html,
body,
#root,
.App {
  height: 100%;
  min-height: 100%;
  margin: 0px;
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/Barlow-Thin.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/Barlow-ExtraLight.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Barlow-Light.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Barlow-Regular.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Barlow-Medium.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Barlow-SemiBold.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Barlow-Bold.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/Barlow-ExtraBold.ttf");
}

@font-face {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/Barlow-Black.ttf");
}

@font-face {
  font-family: "DM Sans";
  font-style: medium;
  font-weight: 500;
  src: url("../assets/fonts/DMSans-Medium.ttf");
}

.more-options-menu .MuiMenuItem-root {
  margin: 5px !important;
  font-weight: 600;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb {
  height: 100px;
  border: 7px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #0e131f;
}

.confirm-modal {
  background-color: #0e131f; /* lightBlack */
  color: #fff; /* white */
}

.confirm-modal .swal2-html-container {
  color: rgba(255, 255, 255, 0.87);
}

.confirm-modal .swal2-confirm {
  background-color: #3772ff; /* primary */
}

.MuiTableCell-root {
  min-height: 74px;
}

.mobile-notification-badge .MuiBadge-badge {
  top: 10px;
  right: 20px;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}
